:root {
	--base-12: 0, 0, 0;
	--base-11: 33, 33, 33;
	--base-10: 61, 61, 61;
	--base-09: 96, 96, 96;
	--base-08: 117, 117, 117;
	--base-07: 132, 132, 132;
	--base-06: 153, 153, 153;
	--base-05: 181, 178, 179;
	--base-04: 201, 199, 200;
	--base-03: 232, 232, 232;
	--base-02: 244, 244, 244;
	--base-01: 255, 255, 255;
	--primary-accent-red: 214, 17, 43;
	--primary-accent-red-light: 255, 86, 84;
	--primary-accent-red-dark: 156, 0, 3;
	--primary-accent-yellow: 255, 238, 0;
	--primary-accent-yellow-light: 255, 255, 87;
	--primary-accent-yellow-dark: 199, 189, 0;
	--primary-accent-white: 255, 255, 255;
	--primary-accent-grey-light: 244, 244, 244;
	--primary-accent-grey-dark: 232, 232, 232;
	--social-media-dark-text: 255, 255, 255;
	--social-media-dark-text-bg: 52, 110, 241;
	--social-media-light-text-bg: 242, 243, 247;
	--primary: var(--primary-accent-red);
	--primary-light: var(--primary-accent-red-light);
	--primary-dark: var(--primary-accent-red-dark);
	--secondary: var(--primary-accent-yellow);
	--secondary-light: var(--primary-accent-yellow-light);
	--secondary-dark: var(--primary-accent-yellow-dark);
	--tertiary: var(--primary-accent-grey-dark);
	--tertiary-light: var(--primary-accent-grey-light);
	--tertiary-dark: var(--base-06);

	// Font Colors
	--font-brand-01: var(--primary-accent-red);
	--font-brand-02: var(--primary-accent-yellow);
	--font-dark: var(--base-11);
	--font-light: var(--base-02);
	--font-supportive: var(--base-06);
	--font-disabled: var(--base-10);

	// Marketing Colors
	--marketing-wine: 176, 49, 67;
	--marketing-coal: 51, 51, 51;
	--marketing-sun: 252, 234, 34;
	--marketing-grass: 30, 186, 35;
	--marketing-gold: 255, 188, 0;
	--marketing-tangerine: 234, 86, 34;

	// Background-images
	--brandContainerBackground: url("data:image/svg+xml;charset=utf8,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 74 35 '%3E%3Cdefs%3E%3Cstyle%3E.cls-1{fill:%23333333;}.cls-2{fill:%23121212;}.cls-3{opacity:0.69;}%3C/style%3E%3C/defs%3E%3Ctitle%3Ebg-content-page%3C/title%3E%3Cpath class='cls-1' d='M-.02 0h74.08v35.04H-.02z'/%3E%3Cellipse class='cls-2' cx='20.72' cy='30.41' rx='4.57' ry='4.59'/%3E%3Cpath class='cls-2' d='M5.63 13a8.1 8.1 0 0 0-2.22.33 4.86 4.86 0 0 0-1.68 1A4.47 4.47 0 0 0 0 17.8 4.58 4.58 0 0 0 2.77 22a4.51 4.51 0 0 0 1.81.37 4.59 4.59 0 0 0 2.74-8.26h7L2.93 35h7.65l11.66-22z'/%3E%3Cellipse class='cls-2' cx='57.7' cy='17.77' rx='4.57' ry='4.59'/%3E%3Cpath class='cls-2' d='M42.6 0a6.79 6.79 0 0 0-2.22.51 5.17 5.17 0 0 0-1.68 1 4.59 4.59 0 0 0 1 7.79 4.52 4.52 0 0 0 1.81.38 4.59 4.59 0 0 0 2.74-8.25l7-.18L39.91 22h7.65L59.21 0z'/%3E%3Cpath class='cls-3' d='M0 0h74.04v35.04H0z'/%3E%3C/svg%3E");

	// System Colors
	--error: 233, 38, 54;
	--success: 0, 205, 100;
	--info: 0, 124, 240;
	--pending: 255, 175, 45;
	--purple: 168, 100, 255;

	// Live Casino
	--active-players-color: 248, 249, 253;
	--full-room-color: 111, 112, 116;

	// --notification-text-color: defaults to rgba(var(--base-02), 1);

	// Progress Meter
	--risk-level-base: var(--base-11);
	--risk-level-0: var(--base-11);
	--risk-level-1: var(--success);
	--risk-level-2: var(--success);
	--risk-level-3: var(--pending);
	--risk-level-4: var(--error);

	// Type Vars
	--display: normal normal 300 7rem 'Aktiv Grotesk', sans-serif;
	--display-2: normal normal 700 5rem/1.18 'Aktiv Grotesk', sans-serif;
	--display-3: normal normal 700 3.6rem/1.222 'Aktiv Grotesk', sans-serif;
	--h1: normal normal 700 2.8rem/1.285 'Aktiv Grotesk', sans-serif;
	--h2: normal normal 700 2.2rem/1.335 'Aktiv Grotesk', sans-serif;
	--h3: normal normal 700 1.9rem/1.265 'Aktiv Grotesk', sans-serif;
	--h4-bold: normal normal 700 1.7rem/1.412 'Aktiv Grotesk', sans-serif;
	--h4-regular: normal normal 400 1.7rem/1.412 'Aktiv Grotesk', sans-serif;
	--heading-5: normal normal 500 1.6rem/1.4375 'Aktiv Grotesk', sans-serif;
	--label-regular: normal normal 500 1.5rem/1.335 'Aktiv Grotesk', sans-serif;
	--label-bold: normal normal 700 1.5rem/1.335 'Aktiv Grotesk', sans-serif;
	--caption: normal normal 500 1.2rem/1.335 'Aktiv Grotesk', sans-serif;
	--caption-bold: normal normal 700 1.2rem/1.335 'Aktiv Grotesk', sans-serif;
	--small: normal normal 500 1.1rem/1.365 'Aktiv Grotesk', sans-serif;
	--small-bold: normal normal 700 1.1rem/1.365 'Aktiv Grotesk', sans-serif;

	// Font Changes 4
	--top-right-corner-link: var(--label-bold);
	//--product-icon-text: defaults to --label-regular;
	--footer-links: var(--label-bold);
	//--footer-flag-language-text: defaults to --label-regular;

	// Notification Inbox
	// Notification counter
	--unread-message-counter-bg-color: var(--primary-accent-yellow);
	--unread-message-counter-text-color: var(--base-11);

	// Notification Header
	--my-notifications-header-bg-color: var(--base-01);
	--my-notifications-back-button-filter: var(--filter-secondary);
	--my-notifications-heading-color: var(--base-11);
	--my-notifications-mark-all-read-color: var(--primary-accent-red);
	--my-notifications-back-button-color: rgba(var(--primary-accent-red), 1);

	// Notification: Unread message
	--unread-message-notifier-color: var(--primary-accent-red);
	--unread-message-title-color: var(--base-11);
	--unread-message-subtitle-color: var(--base-11);
	--unread-message-date-color: var(--base-06);

	// Notification: Read message
	--read-message-color: var(--base-06);
	--read-message-title-color: var(--base-06);

	// Notification: No Data
	--no-notifications-icon-color: var(--base-11);
	--no-notifications-heading-color: var(--base-11);
	--no-notifications-subheading-color: var(--base-06);

	// Notification: Full screen popup
	--notification-popup-bg: var(--base-01);
	--notification-popup-title: var(--base-11);
	--notification-popup-subtitle: var(--base-11);
	--notification-popup-close-icon: var(--base-11);
	--notification-popup-button-bg: var(--primary-accent-red);
	--notification-popup-button-text: var(--primary-accent-yellow);
	--notification-popup-blur-bg: var(--base-11);
	--notification-popup-dot-active: var(--primary-accent-red);
	--notification-popup-dot: var(--primary-accent-red-light);

	// Web-push
	--web-push-popup-bg: var(--base-01);
	--web-push-popup-title: var(--font-dark);
	--web-push-popup-subtitle: var(--font-dark);
	--web-push-popup-button-bg: var(--primary-accent-red);
	--web-push-popup-button-text: var(--primary-accent-red);

	// Source Of Wealth
	--source-of-wealth-help-text: var(--base-11);
	--source-of-wealth-form-bg: var(--base-01);
	--source-of-wealth-form-text: var(--base-11);
	--source-of-wealth-dropdown-bg: var(--base-01);
	--source-of-wealth-dropdown-border: var(--base-03);

	//embed link
	--embed-link-color: var(--primary-accent-red);

	// Landing Page
	--landing-default-bg-color: rgba(var(--base-11), 1);
	--login-btn-bg: rgba(var(--base-01), 1);
	--login-btn-text: rgba(var(--base-11), 1);
	--landing-page-logo: rgba(var(--primary-accent-yellow), 1);
	--logo-wrapper-width: 300px;
	--landing-page-logo-mt: 152px;
	--langing-page-logo-mb: 28px;
	// --landing-page-title: defaults to --base-02
	--product-icon-bg: rgba(var(--base-01), 1);
	--product-icon: rgba(var(--base-11), 1);
	// --product-icon-label: defaults to --base-02
	//--terms-and-condition_bg: defaults to --base-10;
	//--bonus-terms-text: defaults to --base-02
	--bonus-terms-link: rgba(var(--primary-accent-yellow), 1);

	// Head
	--head-bg: rgba(var(--primary-accent-red), 1);
	--page-head-logo: rgba(var(--primary-accent-yellow), 1);
	// --page-head-title: defaults to --base-02
	// --account-text:  defaults to --base-02
	--account-icon: rgba(var(--primary-accent-yellow), 1);
	--balance-circle: rgba(var(--primary-accent-red-dark), 1);
	--balance-icon-wrapper: rgba(var(--primary-accent-yellow), 1);
	--balance-shop-animate-points-wrapper: rgba(var(--base-03), 1);
	// --balance-text:  defaults to --base-02
	--balance-icons: rgba(var(--primary-accent-yellow), 1);
	--page-head-link: rgba(var(--font-light), 1);
	--hamburger-menu: rgba(var(--base-02), 1);
	--balance-header-icon: rgba(var(--primary-accent-red), 1);
	--quick-deposit-icon-color: rgba(var(--base-12), 1);
	--balance-fill-icon-color: rgba(var(--primary-accent-yellow), 1);

	// Chip
	--balance-chip-circle: var(--balance-circle);
	--balance-chip-icon: rgba(var(--primary-accent-yellow), 1);
	--balance-icon-chip-wrapper: rgba(var(--primary-accent-red), 1);
	--balance-chip-text: rgba(var(--font-light), 1);

	// Regulations Header
	--panic-wrapper-bg: rgba(var(--base-12), 1);
	--notActiveProductBg: rgba(var(--base-12), 1);
	--notActiveProductSvg: rgba(var(--base-01), 1);

	// Buttons
	--button-primary-bg: rgba(var(--primary-accent-red), 1);
	--button-primary-bg-hover: rgba(var(--primary-accent-red-light), 1);
	--button-primary-text: rgba(var(--primary-accent-yellow), 1);
	--button-secondary-bg: rgba(var(--base-10), 1);
	// --button-secondary-text: defaults to --base-02
	--button-wrapper-bg: rgba(var(--base-01), 1);
	--button-wrapper-bg-light: rgba(var(--base-02), 1);
	--button-register: rgba(var(--primary-accent-yellow), 1);
	--button-register-text: rgba(var(--font-dark), 1);
	// --button-pag: defaults to --base-11
	--support-button: rgba(var(--base-01), 1);
	--support-button-content: rgba(var(--base-11), 1);
	// --button-border-radius: defaults to 3px
	--button-disabled-bg: rgba(var(--base-03), 1);
	--button-disabled-text: rgba(var(--base-06), 1);
	--button-success-bg: rgba(var(--base-11), 0.25);
	--button-success-color: rgba(var(--base-02), 1);

	// Badges
	--badge-primary-bg: rgba(var(--primary-accent-red), 1);
	--badge-primary-text: rgba(var(--base-02), 1);
	--badge-new-bg: rgba(var(--primary-accent-yellow), 1);
	--badge-new-text: rgba(var(--base-02), 1);
	--badge-dark-bg: rgba(var(--base-12), 1);
	--badge-dark-text: rgba(var(--base-01), 1);

	// Link List
	--link-list: rgba(var(--base-03), 1);

	// Borders
	--list-border-bottom: rgba(var(--base-05), 1);

	// list detail
	--list-detail-value-font: var(--label-regular);
	--list-detail-value: rgba(var(--font-supportive), 1);
	--list-text-color: rgba(var(--base-11), 1);

	// Filters 12
	--filter-bg: rgba(var(--base-11), 1);
	--filter-border-primary: 1px solid rgba(var(--base-11), 1);
	--filter-bg-secondary: rgba(var(--primary-accent-grey-dark), 1);
	--filter-border-secondary: 1px solid rgba(var(--base-11), 1);
	--filter-idle: rgba(var(--base-02), 1);
	--filter-idle-secondary: rgba(var(--base-02), 1);
	--filter-disabled: rgba(var(--base-09), 1);
	--filter-active: rgba(var(--primary-accent-yellow), 1);
	--filter-active-secondary: rgba(var(--primary-accent-red), 1);
	--filter-bar-active: rgba(var(--primary-accent-yellow), 1);
	--filter-bar-active-secondary: rgba(var(--primary-accent-red), 1);
	--filter-bar-inactive: rgba(var(--primary-accent-white), 1);
	--filter-hover: rgba(var(--base-04), 1);
	--filter-hover-secondary: rgba(var(--base-09), 1);
	--filter-support-links: rgba(var(--font-light), 1);

	//Mobile search input field
	--search-bg: rgba(var(--primary-accent-red-dark), 1);
	--search-text: rgba(var(--base-02), 1);
	--search-text-font: normal normal 700 1.6rem/1.335 'Aktiv Grotesk', sans-serif;
	--search-icon: rgba(var(--base-02), 1);
	--search-cancel-font: var(--label-regular);
	--search-icon-bg-color: var(--submenu-item-bg);

	//Mobile search results
	--search-results-bg: rgba(var(--base-03), 1);
	--search-result-item-bg: rgba(var(--base-01), 1);
	--search-results-caption: rgba(var(--base-11), 1);
	--search-results-caption-font: var(--label-bold);
	--search-results-more: rgba(var(--primary-accent-red), 1);
	--search-no-results-text-font: var(--caption);
	--search-no-results-text-color: rgba(var(--base-11), 1);
	--search-ph-gradient-bottom: rgba(var(--base-02), 0.3);
	--search-ph-gradient-top: rgba(var(--base-02), 1);
	--search-ph-mask: rgba(var(--base-01), 1);

	//Desktop search input field
	--search-bg-large: rgba(255, 255, 255, 100%);
	--search-text-large: rgba(var(--base-06), 1);
	--search-text-desktop-placeholder: rgba(var(--font-supportive), 1);
	--search-icon-large: rgba(var(--base-11), 1);

	//Desktop search results
	--search-no-results-desktop-text-font: var(--h3);
	--search-no-results-desktop-text-color: rgba(var(--base-11), 1);
	--desktop-results-wrapper-bg-color: rgba(var(--base-03), 1);

	// Custom checkboxes
	--custom-checkbox-border-color: var(--base-11);
	--custom-checked-checkbox-bg: var(--primary-accent-red);
	--custom-checked-checkbox-border-color: var(--primary-accent-red);
	//--custom-checked-checkmark-color: defaults to base-01
	--custom-checked-disabled-checkbox-bg: var(--base-06);
	--custom-checked-disabled-checkbox-border-color: var(--base-06);
	//--custom-checked-disabled-checkbox-checkmark: defaults to base-01

	// Custom radio buttons
	--custom-radio-border: var(--base-11);
	--custom-checked-radio-bg: var(--primary-accent-red);
	--custom-checked-radio-border-color: var(--base-11);
	--custom-disabled-radio-border-color: var(--base-11);
	--custom-checked-disabled-radio-bg: var(--base-11);
	---custom-checked-disabled-radio-border-color: var(--base-11);

	// Section Head
	--main-icon: rgba(var(--base-11), 1);
	--main-title: rgba(var(--base-11), 1);
	--main-subtitle: rgba(var(--base-06), 1);
	--inverted-main-icon: rgba(var(--base-02), 1);
	--inverted-main-title: rgba(var(--base-02), 1);
	--inverted-main-subtitle: rgba(var(--base-02), 1);
	--section-head-main-icon: rgba(var(--base-11), 1);
	--deposit-confirmation-icon-color: rgba(var(--base-11), 1);

	// Game Tags
	--hot-bg: rgba(var(--primary), 1);
	--new-bg: rgba(var(--secondary), 1);
	--hot-text: rgba(var(--secondary), 1);
	--new-text: rgba(var(--base-11), 1);
	--private-text: rgba(var(--base-11), 1);
	--game-tag-background-color--EXCLUSIVE: rgba(var(--secondary), 1);
	--game-tag-text-color--EXCLUSIVE: rgba(var(--base-11), 1);
	// --vip-text: defaults to --base-02

	--provider-name-line-height: 16px;
	--game-tag-line-height: 17px;
	--active-players-line-height: 15px;
	--stakes-pill-line-height: 16px;

	// Sign In
	--sign-in-box: rgba(var(--base-11), 1);
	--sign-in-box-border: rgba(var(--base-10), 1);

	// Tabs
	--product-tab: rgba(var(--base-11), 1);
	// --product-tab-content: defaults to --base-02
	//--product-tab-border-top: defaults to --base-09;

	// Sidebar
	--sidebar-bg: rgba(var(--base-01), 1);
	--sidebar-box: rgba(var(--base-03), 1);
	// --sidebar-border-left: defaults to the same color as sidebar-bg

	// Payments
	--spinner-loading-animation: rgba(var(--base-11), 1);

	// Footer
	--footer-top-border: rgba(var(--base-11), 1);
	--footer-icon: rgba(var(--font-supportive), 1);
	--footer-bg: rgba(var(--base-11), 1);
	--footer-box: rgba(var(--base-10), 1);
	--footer-text: var(--base-06);
	--language-text: rgba(var(--base-02), 1);
	--footer-payment-icon: rgba(var(--font-supportive), 1);
	--footer-payment-logo-bg: rgba(var(--base-01), 1);
	// --footer-social-icon: defaults to --base-02
	--footer-18-icon: rgba(var(--error), 1);
	--footer-18-icon-text: rgba(var(--base-01), 1);
	--footer-logo-18-height: 45px;
	--footer-logo-18-width: 45px;
	--flag-eu: rgba(var(--base-04), 1);
	--flag-eu-dots: rgba(var(--base-11), 1);
	--footer-link-color: rgba(var(--font-light), 1);

	// Hero
	--hero-bg: rgba(var(--base-11), 1);
	--hero-img: var(--brandContainerBackground);
	--hero-font-color: rgba(var(--base-01), 1);
	--hero-pattern-size: 74px 35px;
	--hero-bg-position: left top;
	--hero-bg-repeat: repeat;

	// SEO-content 3
	--seo-read-more-btn: var(--primary-accent-yellow);
	--seo-content-border-bottom: rgba(var(--base-10), 1);
	--seo-content-bg: rgba(var(--base-11), 1);

	// Card
	--box-bg: rgba(var(--base-01), 1);
	--browse-circle-bg: rgba(var(--base-03), 1);
	--browse-circle-icon: rgba(var(--base-11), 1);
	--open-in-new-window-icon: var(--font-supportive);

	// Game card
	--bowtie-guy: rgba(var(--base-06), 1);

	// Loader
	--loading-animation-dots-color: rgba(var(--primary-accent-red), 1);

	//Slider input
	--slider-empty-track: var(--base-03);

	// Bonus welcome package
	--bonus-welcome-package-item-bg: rgba(var(--primary-accent-grey-light), 1);
	--bonus-welcome-package-item-text-color: rgba(var(--font-dark), 1);
	--bonus-welcome-package-nested-collapse-bg: rgba(var(--primary-accent-beige-light), 1);
	--bonus-welcome-package-icon-color: rgba(var(--font-light), 1);
	--bonus-welcome-package-light-text-color: rgba(var(--font-light), 1);
	--bonus-welcome-package-item-read-more-button-box: rgba(var(--primary-accent-grey-light), 1);

	// Active bonus
	--active-bonus-progress-lane: rgba(var(--base-03), 1);
	--active-bonus-progress-bar: rgba(var(--primary-accent-red), 1);

	// Virtuals
	// --virtuals-root-bg-color: var(--base-09);

	// Highlight-games
	// --highlight-games-bg-primary: defaults to rgba(var(--base-10), 1);
	// --highlight-games-bg-secondary: defaults to rgba(var(--base-11), 1);
	// --highlight-games-bg-tertiary: defaults to rgba(var(--base-12), 1);
	// --highlight-games-text-primary: defaults to rgba(var(--base-02), 1);
	// --highlight-games-text-secondary: defaults to rgba(var(--base-06), 1);
	// --highlight-games-text-disabled: defaults to rgba(var(--base-06), 1);
	// --highlight-games-text-secondary-disabled: defaults to rgba(var(--base-07), 1);
	// --highlight-games-betslip-balance-wrapper: defaults to rgba(var(--base-11), 1);
	// --highlight-games-betslip-balance-icon-color: defaults to rgba(var(--base-02), 1);
	// --highlight-games-betslip-border-color: defaults to rgba(var(--base-09), 1);
	// --highlight-games-betslip-header-text-color: defaults to rgba(var(--highlight-games-text-primary), 1);
	// --highlight-games-mobile-betslip-bg: defaults to var(--highlight-games-bg-tertiary);
	// --highlight-games-video-player-background: defaults to rgba(var(--base-12));
	// --highlight-games-mute-icon-color: defaults to rgba(var(--base-01));
	// --highlight-games-bet-tab-label-text-color: defaults to rgba(var(--base-02), 1);
	// --highlight-games-pending-bet-color: defaults to rgba(var(--pending), 1);
	// --highlight-games-bet-body-bg-color: defaults to rgba(var(--base-11);
	// --highlight-games-bet-betline-bg-color: defaults to rgba(var(--base-10);
	// --highlight-games-void-bet-color: defaults to rgba(var(--base-02);
	//--highlight-games-clear-bets-btn-color: defaults to rgba(var(--base-10), 1);
	// --highlight-games-market-count-text-color: defaults to rgba(var(--base-04), 1);
	// --highlight-games-bet-tab-text-color: rgba(var(--base-06), 1);
	// --highlight-games-week-event-border: defaults to 0;
	// --highlight-games-week-event-hover-border: defaults to 0;

	// Onboarding
	// --highlight-games-bg-secondary: defaults to rgba(var(--base-11), 1)
	// --onboarding-backdrop-bg: defaults to rgba(var(--base-11), 0.85);
	// --onboarding-item-bg: defaults to rgba(var(--base-10),1);

	// Sportsbook
	// StakeInput
	//--stake-input-button-bg-color: var(--button-primary-bg);
	//--stake-input-button-disabled-color: rgba(var(--base-10), 1);
	//--stake-input-bg-color: defaults to rgba(var(--base-12), 1);
	//--stake-input-text-color: defaults to rgba(var(--base-10), 1);

	// Transparent bg
	--background-alpha: rgba(var(--base-11), 0.85);

	// Split view
	--background-split-view: rgba(var(--base-11), 1);

	// Lotteries
	--lotteries-cash-svg-background: rgba(var(--base-09), 1);
	--lotteries-cash-svg-fill: rgba(var(--base-02), 1);

	// propose-locale
	//--propose-locale-text: defaults to grey-50;

	// New Game Card
	--gameCardTitleDetailColor: var(--base-11);
	--gameCardTitleDetailFont: var(--label-bold);
	--gameCardJackpotBg: var(--button-primary-bg);
	--gameCardJackpotText: rgba(var(--font-light), 1);
	--gameCardJackpotFont: var(--label-bold);
	--gameCardJackpotFontSmall: var(--small-bold);
	--gameCardDetailBg: var(--base-01);
	--gameCardTitle: var(--base-11);
	--gameCardTitleSmall: var(--base-06);
	--gameCardWinnerBg: var(--base-01);
	--gameCardDotBg: var(--primary-accent-red);

	// Game Info
	--gameInfoBg: var(--base-01);
	--gameInfoTitleName: var(--base-11);
	--gameInfoTitleProvider: var(--base-06);
	--gameInfoLoveButton: var(--base-03);
	--gameInfoLoveIcon: var(--primary-accent-red);
	--gameInfoDescText: var(--base-11);
	--gameInfoButtonBorder: var(--primary-accent-red);
	--gameInfoButtonFont: var(--h4-bold);
	--gameInfoButtonText: var(--primary-accent-red);
	--gameInfoDetailBg: var(--base-03);
	--gameInfoDetailTitle: var(--base-06);
	--gameInfoDetailAmount: var(--base-11);
	--gameInfoPlayRealButtonBg: var(--primary-accent-red);
	--gameInfoPlayRealButtonText: var(--primary-accent-yellow);
	--game-info-exit-icon-color: rgba(var(--base-01), 1);

	// Explore Category Carousel
	--category-arrow-icon-color: rgba(var(--primary-accent-red), 1);
	--category-arrow-bg: var(--base-01);
	--category-title-color: var(--base-11);
	--category-link-color: var(--font-brand-01);

	//Explore Category Page
	--category-page-bg: var(--base-03);
	--category-page-title-color: var(--base-11);
	--category-exit-icon-color: rgba(var(--primary-accent-red), 1);

	// Skeleton placeholders
	--skeleton-placeholder-bg: rgba(var(--base-03), 1);
	--skeleton-placeholder-bg-gradient: linear-gradient(
		-90deg,
		var(--skeleton-placeholder-bg) 0%,
		rgba(var(--base-05), 1) 50%,
		var(--skeleton-placeholder-bg) 100%
	);

	// Shop / Promotions
	--shop-points-balance-bg: rgba(var(--primary-accent-red), 1);
	--shop-points-balance-button-bg: rgba(var(--primary-accent-red-dark), 1);
	--shop-points-faq-button: rgba(var(--primary-accent-red), 1);
	--bonus-item-detail-bg: rgba(var(--primary-accent-white), 1);
	--bonus-item-foreground-text: rgba(var(--font-light), 1);
	--bonus-detail-pill-bg: rgba(var(--primary-accent-red), 1);
	--bonus-detail-close-icon-color: rgba(var(--font-dark), 1);
	--shop-filter-bar-bg: rgba(var(--base-11), 1);

	// Filter pills
	--filter-pills-bg: rgba(var(--base-01), 1);
	--filter-pills-bg-active: rgba(var(--primary-accent-red), 1);
	--filter-pills-text-color-active: rgba(var(--base-01), 1);
	--filter-pills-text-color: rgba(var(--base-11), 1);

	// Submenu items
	--submenu-bg--desktop: var(--filter-bg-secondary);
	--submenu-item-bg--desktop: rgba(var(--base-01), 1);
	--submenu-item-bg-active--desktop: rgba(var(--primary-accent-red), 1);
	--submenu-item-text-color--desktop: rgba(var(--base-11), 1);
	--submenu-item-text-color-active--desktop: rgba(var(--primary-accent-yellow), 1);
	--submenu-item-border-color--desktop: transparent;
	--submenu-item-border-color-active--desktop: transparent;
	--submenu-item-bg: rgba(var(--primary-accent-red-dark), 1);
	--submenu-item-bg-active: rgba(var(--primary-accent-red), 1);
	--submenu-item-text-color: rgba(var(--base-01), 1);
	--submenu-item-text-color-active: rgba(var(--primary-accent-yellow), 1);
	--submenu-item-border-color: transparent;
	--submenu-item-border-color-active: rgba(var(--primary-accent-yellow), 1);

	// Cookie Banner
	--cookie-policy-link: rgba(var(--primary-accent-red), 1);
	//--policy-version-text: defaults to --language-text
	--cookie-toggle-bg-on: rgba(var(--primary-accent-red-light), 1);
	--cookie-toggle-bg-off: rgba(var(--base-03), 1);
	--cookie-toggle-knob-on: rgba(var(--primary-accent-red), 1);
	--cookie-toggle-knob-off: rgba(var(--primary-accent-red), 1);
	--cookiebanner-wrapper-bg: rgba(var(--base-01), 1);
	--cookie-policy-footer-bg: rgba(var(--base-01), 1);
	--cookie-policy-footer-wrap-divider: var(--base-04);
	--cookie-footer-policy-text: rgba(var(--base-11), 1);
	--cookie-footer-wrap-big-bg: rgba(var(--base-03), 1);
	--footer-wrapper-big-divider: var(--base-03);
	--cookie-footer-wrap-small-bg: rgba(var(--base-03), 1);
	--footer-wrapper-small-divider: var(--base-03);
	--cookie-accept-all-text-big: rgba(var(--primary-accent-yellow), 1);
	--cookie-accept-all-text-small: rgba(var(--primary-accent-yellow), 1);
	--cookie-save-settings-btn-text: rgba(var(--primary-accent-yellow), 1);
	--cookie-banner-text: rgba(var(--font-supportive), 1);
	--cookie-accept-settings-text: rgba(var(--primary-accent-yellow), 1);
	--cookie-accept-settings-small-text: rgba(var(--primary-accent-yellow), 1);
	--cookie-save-settings-btn-bg: rgba(var(--primary-accent-red), 1);
	--cookie-small-save-settings-btn-bg: rgba(var(--primary-accent-red), 1);
	--cookie-small-save-settings-btn-text: rgba(var(--primary-accent-yellow), 1);
	--cookie-link-supportive: rgba(var(--font-supportive), 1);
	--cookie-banner-logo: var(--page-head-logo);

	// Lists
	--list-item-background-color: rgba(var(--base-10), 1);

	// Cards
	// --card-bg: defaults to var(--box-bg);
	--lc-live-casino-card-bg: rgba(var(--base-11), 0.85);
	--lc-card-font-size: 11px;
	// --card-header-caption-font: defaults to --caption;
	// --card-header-caption-font-color: defaults to rgba(var(--base-06));
	// --card-header-title-font: defaults to --label-bold;
	--card-header-title-font-color: rgba(var(--base-11), 1);
	--card-body-text-font-color: var(--base-11);
	// --card-media-pill-bg defaults to card-bg
	// --card-media-pill-font defaults to --caption
	// --card-media-pill-border-radius defautls 12px;

	// UI Button dots

	// --button-dot-active-bg: defaults to rgba(var(--primary-accent-yellow), 1)
	// --button-dot-bg: defaults to var(--button-secondary-bg, rgba(var(--base-08), 1))

	// Modal
	--modal-wrapper-bg: rgba(var(--primary-accent-white), 1);

	// --action-sheet-button-text-color: defaults to rgba(var(--base-02);
	// --action-sheet-button-bg: defaults to rgba(var(--base-08)

	//quick-deposit-modal
	--qd-modal-contrast-text: var(--primary-accent-red);
	--qd-modal-cancel-text: var(--font-dark);

	//Stepper (registration wizard)
	--stepper-active: rgba(var(--primary-accent-red), 1);
	--custom-stepper-active-color: rgba(var(--base-11), 1);
	--stepper-active-text: rgba(var(--base-01), 1);
	--stepper-inactive: rgba(var(--primary-accent-grey-dark), 1);
	--linear-stepper-inactive: rgba(var(--base-11), 1);

	//Scroll Down Overlay - SGA Sportsbook Relieve
	--scrollDownContainerBg: linear-gradient(0deg, #fff 0%, #f4f4f4 53.88%, rgba(244, 244, 244, 0%) 77.78%);

	//Player Info Screen
	--player-info-bg: rgba(var(--base-01), 1);
	--player-info-champagne: rgba(var(--primary-accent-red), 1);
	--player-info-title: rgba(var(--base-12), 1);
	--player-info-desc: rgba(var(--font-supportive), 1);
	--player-info-cgr-bg: rgba(var(--primary-accent-grey-light), 1);
	--player-info-cgr-title: rgba(var(--base-12), 1);
	--player-info-cgr-desc: rgba(var(--font-supportive), 1);
	--player-info-balance-value: rgba(var(--base-12), 1);
	--player-info-balance-desc: rgba(var(--base-12), 1);

	// Carousel
	--carouselTitleFont: var(--h2);
	--carouselTitleFontLarge: var(--display-3);
	--carouselTitleColor: var(--base-02);
	--carouselDescFont: var(--label-regular);
	--carouselDescFontLarge: var(--h4-bold);
	--carouselDescColor: var(--base-02);
	--carouselDotBg: var(--primary-accent-grey-dark);
	--carouselDotActiveBg: var(--primary-accent-red);
	--carouselArrow: var(--primary-accent-red);
	--carouselArrowBg: rgba(var(--base-01), 1);

	//multiLayer Carousel
	--multiLayerCarouselTitleFont: var(--h3);
	--multiLayerCarouselTitleFontLarge: var(--display-2);
	--multiLayerCarouselDescFont: var(--caption);
	--multiLayerCarouselDescFontLarge: var(--h4-regular);
	--multiLayerCarouselButtonColor: var(--primary-accent-yellow);
	--multiLayerCarouselButtonFont: var(--label-bold);
	--multiLayerCarouselButtonFontLarge: var(--h3);
	--bannerTermsConditionsBackground: rgba(var(--base-11), 1);
	--bannerTermsConditionsOverlay: rgba(0, 0, 0, 80%);
	--bannerTermsConditionsColor: rgba(var(--font-light), 1);
	--bannerTermsConditionsFont: var(--small);

	// Binary Question
	--binary-question-inner-background: rgba(var(--primary-accent-white), 1);
	--binary-question-divider-color: rgba(var(--primary-accent-grey-light), 1);

	// tournaments
	--tournaments-sidebar-background: rgba(var(--primary-accent-white), 1);
	--tournaments-sidebar-surface: rgba(var(--primary-accent-grey-light), 1);
	--tournaments-sidebar-divider-on-background: rgba(var(--primary-accent-grey-dark), 1);
	--tournaments-sidebar-divider-on-surface: rgba(var(--primary-accent-white), 1);
	--tournaments-text-color-overwrite: unset;
	--tournaments-text-on-tag: rgba(var(--font-light), 1);
	--tournaments-button-primary-bg: var(--button-primary-bg);
	--tournaments-rank-highlight: rgba(var(--primary-accent-yellow), 1);
	--tournaments-rank-on-highlight: rgba(var(--font-dark), 1);
	--tournaments-rank-avatar-on-background: rgba(var(--primary-accent-grey-light), 1);
	--tournaments-rank-avatar-on-surface: rgba(var(--primary-accent-white), 1);
	--tournaments-rank-avatar-on-highlight: rgba(var(--base-12), 1);
	--tournaments-rank-avatar-win: rgba(var(--primary-accent-yellow), 1);
	--tournaments-rank-avatar-on-win: rgba(var(--font-dark), 1);
	--tournaments-rank-divider-on-background: rgba(var(--primary-accent-grey-dark), 1);
	--tournaments-results-title: rgba(var(--font-dark), 1);
	--tournaments-results-trophy: rgba(var(--font-dark), 1);
	--tournaments-results-pill-icon: rgba(var(--font-dark), 1);
	--tournaments-list-header-button: var(--button-primary-bg);
	--tournaments-list-item-details-button-text: rgba(var(--font-brand-01), 1);
	--tournaments-list-item-details-button-icon: rgba(var(--font-dark), 1);
	--tournaments-list-item-timer: rgba(var(--font-supportive), 1);
	--tournaments-modal-header-button-background: rgba(var(--font-dark), 0.15);
	--tournaments-details-caption-alt: rgba(var(--font-supportive), 1);
	--tournaments-details-tab-border: rgba(183, 184, 188, 100%);
	--tournaments-label-regular: normal normal 400 1.5rem/1.333 'Aktiv Grotesk', sans-serif;
	--tournaments-label-bold: normal normal 700 1.5rem/1.333 'Aktiv Grotesk', sans-serif;
	--tournaments-caption: normal normal 400 1.2rem/1.333 'Aktiv Grotesk', sans-serif;
	--tournaments-caption-bold: normal normal 700 1.2rem/1.333 'Aktiv Grotesk', sans-serif;
	--tournaments-small: normal normal 400 1.1rem/1.272 'Aktiv Grotesk', sans-serif;
	--tournaments-small-bold: normal normal 700 1.1rem/1.272 'Aktiv Grotesk', sans-serif;
	--tournaments-name-transform: none;

	// quick deposit mobile toolbar
	--quick-deposit-modal-bg: rgba(var(--primary-accent-white), 1);
	--quick-deposit-amount-input-bg: rgba(var(--primary-accent-grey-light), 1);
	--quick-deposit-methods-collapse-bg: rgba(var(--primary-accent-grey-light), 1);
	--quick-deposit--toolbar-background: rgba(var(--primary-accent-red), 1);
	--quick-deposit--button-wrapper-background: var(--primary-accent-yellow);
	--quick-deposit--button-wrapper-border: var(--primary-accent-red-dark);
	--quick-deposit--text-color: var(--base-11);
	--quick-deposit--icon-container-color: var(--base-11);

	// inhouse jackpot
	--inhouse-jackpot--btn-bg-color: var(--primary-accent-red-dark);
	--inhouse-jackpot--btn-bg-color--opted-in: var(--primary-accent-red);
	--inhouse-jackpot--btn-bg-color--desktop: var(--primary-accent-red-dark);
	--inhouse-jackpot--btn-border-color--opted-in: var(--primary-accent-red-dark);
	--inhouse-jackpot--btn-border-color: var(--primary-accent-red-dark);
	--inhouse-jackpot--btn-text-color: var(--base-02);
	--inhouse-jackpot--btn-text-color--opted-in: var(--primary-accent-yellow);
	--inhouse-jackpot--btn-text-color--desktop: var(--inhouse-jackpot--btn-text-color);
	--inhouse-jackpot--icon-container-bg-color--desktop: var(--primary-accent-red);
	--inhouse-jackpot--icon-container-bg-color--desktop--opted-in: var(--primary-accent-red);
	--inhouse-jackpot--icon-container-color: var(--primary-accent-yellow);
	--inhouse-jackpot--icon-container-color--desktop: var(--primary-accent-yellow);
	--inhouse-jackpot--icon-container-color--opted-in: var(--primary-accent-yellow);
	--inhouse-jackpot--icon-container-color--desktop--opted-in: var(--primary-accent-yellow);
	--inhouse-jackpot--modal-background-color: var(--base-01);
	--inhouse-jackpot--modal-header-close-icon-color: var(--base-01);
	--inhouse-jackpot--modal-header-close-icon-color--terms-activated: var(--primary-accent-red);
	--inhouse-jackpot--modal-contribution-info-color: var(--base-02);
	--inhouse-jackpot--modal-content-title-color: var(--base-11);
	--inhouse-jackpot--modal-content-description-color: var(--base-11);
	--inhouse-jackpot--modal-content-show-terms-link-color: var(--primary-accent-red);
	--inhouse-jackpot--modal-content-terms-description-color: var(--base-06);
	--inhouse-jackpot--modal-content-terms-close-color: var(--primary-accent-red);
	--inhouse-jackpot--modal-content-terms-title-color: var(--base-11);
	--inhouse-jackpot--modal-optin-button-font: var(--label-bold);
	--inhouse-jackpot--modal-optin-button-color: var(--primary-accent-yellow);
	--inhouse-jackpot--modal-optout-button-color: var(--primary-accent-red);
	--inhouse-jackpot--modal-optout-button-border-color: var(--primary-accent-red);
	--inhouse-jackpot--modal-tiers-text-color: var(--base-02);
	--inhouse-jackpot--win-modal-color: var(--base-02);
	--inhouse-jackpot--win-modal-border-color: var(--primary-accent-yellow);
	--inhouse-jackpot--win-modal-close-button-font: var(--h3);
	--inhouse-jackpot--win-modal-close-button-color: var(--primary-accent-yellow);

	//bankid loading screen
	--bankid-loader-bg: rgba(var(--base-11), 0.98);
	--bankid-loader-title: rgba(var(--base-01), 1);
	--bankid-loader-desc: rgba(var(--base-01), 1);
	--bankid-loader-dots: rgba(var(--primary-accent-yellow), 1);

	// Credit Card
	--credit-card: rgba(var(--base-02), 1);
	--collapse-view-chevron: rgba(var(--font-supportive), 1);
	--cc-indicator: rgba(var(--base-02), 1);
	--cc-indicator-active: rgba(var(--primary-accent-red), 1);
	--cc-card-add-btn: rgba(var(--primary-accent-grey-light), 1);
	--cc-card-add-btn-text: rgba(var(--primary-accent-red), 1);

	// Limits
	--limits-notification-icon: var(--base-11);

	//notification-warning-banner
	--notif-banner-bg: rgba(var(--base-02), 1);
	--notif-banner-icon: rgba(var(--base-11), 1);

	// Responsible Gaming
	--time-limit-reached-label-color: var(--font-supportive);
	--time-limit-reached-timer-color: var(--font-dark);

	// New input colors
	--input-background-color: var(--primary-accent-grey-dark);
	--input-disabled-background-color: var(--primary-accent-grey-dark);
	--input-border-color: var(--base-06);
	--input-border-hover-color: var(--primary-accent-red);
	--input-border-focus-color: var(--primary-accent-red);
	--input-border-disabled-color: var(--base-06);
	--input-border-error-color: var(--primary-accent-red);
	--input-placeholder-color: var(--font-supportive);
	--input-placeholder-disabled-color: var(--base-04);
	--input-font-color: var(--font-dark);
	--input-font-focus-color: var(--font-brand-01);
	--input-font-disabled-color: var(--base-04);
	--input-error-color: var(--primary-accent-red);
	--input-helper-text-color: var(--font-dark);
	--select-arrow-color: var(--font-dark);
	--toggle-group-selected-color: var(--primary-accent-red);
	--toggle-group-color: var(--font-supportive);

	// Support nav
	--opacity-overlay: var(--base-11);
	--propose-locale-head-bg: var(--primary-accent-white);
	--propose-locale-border: var(--opacity-overlay);
	--propose-locale-text: var(--font-dark);

	// Social media
	--social-media-button-background: var(--social-media-dark-text-bg);
	--social-media-button-background-icon: var(--social-media-light-text-bg);
	--social-media-button-text: var(--social-media-dark-text);

	// Marketplace & Shop
	--marketplace-success-text-color: rgba(var(--base-12), 1);
	--marketplace-success-main-icon: var(--section-head-main-icon);
	--marketplace-continue-btn-text-color: rgba(var(--primary-accent-red), 1);
	--marketplace-success-subtext-color: rgba(var(--base-08));
	--marketplace-success-balance-color: rgba(var(--base-08));
	--marketplace-success-balance-sum-color: rgba(var(--base-12));
	--marketplace-success-close-icon-color: rgba(var(--primary-accent-red), 1);
	--marketplace-progress-bg: rgba(var(--primary-accent-white), 1);
	--marketplace-progress-bar-bg: rgba(var(--primary-accent-red), 1);

	//Mentor UI Gaming Habits -- Playing Behavior box --
	--button-selection-days-select-color: rgba(var(--primary-accent-red), 1);
	--button-selection-days-color: rgba(var(--primary-accent-grey-light), 1);
	--playing-behaviour-pills-text-selected: rgba(var(--primary-accent-yellow), 1);
	--playing-behaviour-percentage-text: var(--font-disabled);
	--playing-behaviour-description-text: rgba(var(--font-supportive), 1);
	--game-type-icon-color: var(--base-11);
	--your-behaviour-box-chart: rgba(var(--primary-accent-red), 1);
	--comparable-players-box-chart: rgba(var(--primary-accent-grey-light), 1);
	--box-info-data-player: rgba(var(--primary-accent-grey-light), 1);
	--vertical-line-others: rgba(var(--primary-accent-yellow), 1);

	// Transaction history

	--filter-pills-bg-th: var(--primary-accent-grey-dark);
	--filtering-pills-bg: var(--primary-accent-red);
	--filtering-pills-disable: var(--primary-accent-grey-dark);
	--filtering-pills-hover: var(--primary-accent-red-light);
	--filtering-pill-font-color: var(--font-brand-02);
	--filtering-pill-font-color-dark: var(--font-dark);

	// Transaction history notification
	--transaction-history-notification-background-color: rgba(var(--primary-accent-grey-light), 1);
	--transaction-history-notification-icon-color: var(--font-dark);

	// Pill
	--pill-checked-label-color: rgba(var(--primary-accent-yellow), 1);

	// Communication popup
	--communication-popup-background-color: rgba(var(--primary-accent-white), 1);
	--communication-popup-title-color: rgba(var(--font-dark), 1);

	// Game Info
	--game-info-section-background-color: rgba(var(--base-01), 1);
	--game-info-separator-color: rgba(var(--tertiary), 1);
	--game-info-background-color: rgba(var(--tertiary-light), 1);
	--game-info-button-background-color: rgba(var(--base-01), 1);
	--game-info-card-color: rgba(var(--tertiary-light), 1);
	--game-info-menu-background: rgba(var(--tertiary-light), 1);
	--game-info-menu-icon-color: rgba(var(--font-dark), 1);
	--game-info-volatility-icon-color: rgba(var(--primary), 1);
	--game-info-collapse-icon-color: rgba(var(--primary), 1);
	--game-info-jackpot-amount-text-color: rgba(var(--font-dark), 1);
	--game-info-row-value-text-color: rgba(var(--font-dark), 1);
	--game-info-name-text-color: rgba(var(--font-light), 1);
	--game-info-provider-text-color: rgba(var(--font-light), 0.6);
	--game-info-collapse-header-text-color: rgba(var(--font-dark), 1);
	--game-info-play-button-background-color: rgba(var(--primary), 1);
	--game-info-play-button-color: rgba(var(--font-brand-02), 1);
}
